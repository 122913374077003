<script setup>
import ViewBills from "./ViewBills.vue";
import ActionButton from "@/components/utils/ActionButton.vue";
import { billStatuses } from "./view_bill_business_logic";
import SectionTitle from "../SectionTitle.vue";
import { useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import { countyIsKirinyaga } from "@/utils/vue_helpers";
import { computed, ref, watchEffect } from "vue";
import { actions } from "@/utils/constants";
import { arrayNotEmpty } from "@/utils/functions";

const { router, route } = useRouter();

watchEffect(() => {
  const routeTabQuery = route.value?.query?.tab ?? null;
  const routeHasTabQuery = !!routeTabQuery;
  if (routeHasTabQuery) {
    const shouldGoToUnpaid = routeTabQuery === "unpaid";
    const shouldGoToPaid = routeTabQuery === "paid";
    if (shouldGoToUnpaid) {
      currentTab.value = tabs.UNPAID_BILLS;
    } else if (shouldGoToPaid) {
      currentTab.value = tabs.PAID_BILLS;
    }
  }
});

const tabs = {
  UNPAID_BILLS: 0,
  PAID_BILLS: 1,
};

const currentTab = ref(tabs.UNPAID_BILLS);

function getBillIdsAsCommaSeparatedString(bills) {
  let billIdsString = "";
  bills.forEach((bill, index) => {
    billIdsString += bill.id;
    const notLastItem = index !== bills.length - 1;
    if (notLastItem) {
      billIdsString += ",";
    }
  });
  return billIdsString;
}

async function onPayBillClicked(bill) {
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: bill.bill_number,
    directPayment: true,
    billsToPay: [bill],
  });
  router.value.push(paymentRoute);
}

const tabText = computed(() => {
  if (countyIsKirinyaga.value) {
    return {
      unpaid: "Unpaid Bills",
      paid: "Receipts",
    };
  }
  return {
    unpaid: "Unpaid",
    paid: "Paid",
  };
});

const billsSelectedForPayment = ref([]);

function billIsSelectedForPayment(bill) {
  const selectedBill = billsSelectedForPayment.value.find(
    (billToPay) => billToPay.bill_number === bill.bill_number
  );
  const selectedBillNotEmpty = selectedBill !== undefined;
  return selectedBillNotEmpty;
}

const atLeastOneBillMultiSelected = computed(() => {
  return arrayNotEmpty(billsSelectedForPayment.value);
});

function onPayForMultipleBillsClicked() {
  const billNumbersAsCommaSeparatedString = billsSelectedForPayment.value
    .map((bill) => bill.bill_number)
    .join(",");
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: billNumbersAsCommaSeparatedString,
    directPayment: true,
    billsToPay: billsSelectedForPayment.value,
  });
  router.value.push(paymentRoute);
}

function comparatorFn(a, b) {
  return a.bill_number === b.bill_number;
}
</script>

<template>
  <div>
    <SectionTitle title="Bills And Receipts" />

    <v-tabs
      v-model="currentTab"
      :class="{
        'pt-4 pt-md-0 justify-center justify-md-start': true,
        'd-flex': $mobileBreakpoint,
      }"
    >
      <v-tabs-slider color="secondary" />

      <v-tab v-for="text in tabText" :key="text" class="text-none">
        {{ text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab" touchless class="pt-4 pt-md-0">
      <v-tab-item>
        <ViewBills
          :billStatusToFetch="billStatuses.UNPAID"
          showPayForBill
          :showPayForMultipleBillsBtn="atLeastOneBillMultiSelected"
          :showUnselectBillsBtn="atLeastOneBillMultiSelected"
          @unselectBillsClicked="billsSelectedForPayment = []"
          @payForMultipleBillsClicked="onPayForMultipleBillsClicked"
          @payForBill="onPayBillClicked"
        >
          <template #multiSelect="{ bill }">
            <v-checkbox
              :key="bill.bill_number"
              dense
              hide-details
              v-model="billsSelectedForPayment"
              :value="bill"
              :value-comparator="comparatorFn"
            />
          </template>

          <template #paymentButton="{ bill }">
            <ActionButton
              v-if="!billIsSelectedForPayment(bill)"
              :action="actions.pay"
              inTable
              @click="onPayBillClicked(bill)"
            />
          </template>
        </ViewBills>
      </v-tab-item>
      <v-tab-item>
        <ViewBills
          :billStatusToFetch="billStatuses.PAID"
          :showPrintReceipt="true"
          inBillsReceiptsMode
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
