import apiCall from "@/utils/api";
import { filterTypes, vehicleQueryKeys } from "@/utils/constants";
import {
  downloadBlob,
  getFilterItem,
  getVehicleBill,
  getVehicleSticker,
} from "@/utils/functions";
import {
  useCallSpringUrl,
  useFilters,
  usePaginatedDataWithDebouncedSearch,
} from "@/utils/vue_helpers";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { watch, computed, reactive, ref } from "vue";

export function useViewVehicles() {
  const {
    paginationData: vehiclesData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  } = usePaginatedDataWithDebouncedSearch();

  const formData = useGetVehicleFormData();

  const vehicleTypes = computed(() => {
    const vehicleTypes = formData.data.value?.vehicle_types ?? [];
    return vehicleTypes.map((vehicleType) => vehicleType.type);
  });

  const filters = useFilters(
    ref([
      getFilterItem({
        name: "Status",
        requestName: "status",
        value: "",
        items: ["Active", "Expired", "Archived"],
        type: filterTypes.array,
        color: "primary",
      }),
      getFilterItem({
        name: "Vehicle Type",
        requestName: "vehicle_type",
        value: "",
        items: vehicleTypes,
        type: filterTypes.array,
        color: "primary",
        loading: computed(() => formData.isLoading.value),
      }),
    ])
  );

  const url = computed(() => {
    let url = `/api/client/vehicle?page=${vehiclesData.paginationData.currentPage}`;
    if (debouncedSearchModelNotEmpty.value) {
      url = url + `&type=search&search=${debouncedSearchModel.value}`;
    }
    if (filters.filterString.value !== "") {
      url += "&filter=true&" + filters.filterString.value;
    }
    return url;
  });

  watch(
    url,
    (newVal) => {
      vehiclesData.fetchData(newVal);
    },
    {
      immediate: true,
    }
  );

  return {
    vehiclesData,
    searchModel,
    filters,
  };
}

export function useGenerateVehicleBill() {
  async function generateVehicleBill({
    vehicleAccountNumber,
    createdBy,
    period,
    remarks,
    subCountyId,
    vehicleIds,
  }) {
    try {
      const requestData = {
        account_no: vehicleAccountNumber,
        created_by: createdBy,
        period,
        remarks,
        sub_county_id: subCountyId,
        vehicles: vehicleIds,
      };
      const response = await apiCall({
        url: "/api/client/vehicle/generate-bill",
        data: requestData,
        method: "POST",
      });
      const bill = response;
      return bill;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const generateVehicleBillMutation = useMutation({
    mutationFn: generateVehicleBill,
  });

  return generateVehicleBillMutation;
}

export function useDownloadVehicleSticker() {
  async function downloadVehicleSticker({ vehicle }) {
    const vehicleBill = getVehicleBill(vehicle);
    if (!!vehicleBill) {
      try {
        const response = await apiCall({
          url: "/api/client/vehicle/generate-vehicle-sticker-pdf",
          method: "POST",
          responseType: "blob",
          data: {
            vehicle_bill_id: vehicleBill.id,
            vehicle_ids: [vehicle.id],
          },
        });
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    }
  }

  const downloadVehicleStickerMutation = useMutation({
    mutationFn: downloadVehicleSticker,
  });

  return { downloadVehicleStickerMutation };
}

export function useBulkDownloadVehicleStickers() {
  async function downloadVehicleStickers({ vehicleIds }) {
      try {
        const response = await apiCall({
          url: "/api/client/vehicle/generate-bulk-vehicle-sticker-pdf",
          method: "POST",
          responseType: "blob",
          data: {
            vehicle_ids: vehicleIds,
          },
        });
        return response;
      } catch (error) {
        console.log(error);
        throw error;
      }
  }

  const bulkDownloadVehicleStickersMutation = useMutation({
    mutationFn: downloadVehicleStickers,
    onSuccess(data){
      downloadBlob({ blob: data, filename: "vehicle-stickers" });
    }
  });

  return { bulkDownloadVehicleStickersMutation };
}

export function useGetVehicleFormData() {
  async function getVehicleFormData() {
    try {
      const response = apiCall({
        url: "/api/client/vehicle?type=form_data",
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const vehicleFormDataQuery = useQuery({
    queryFn: getVehicleFormData,
    queryKey: vehicleQueryKeys.formData,
    refetchOnWindowFocus: false,
  });

  return vehicleFormDataQuery;
}

export function useDownloadVehicleCsvData() {
  async function downloadVehicleCsvData() {
    try {
      const response = apiCall({
        url: "/api/client/vehicle?type=csv",
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const downloadVehicleCsvDataMutation = useMutation({
    mutationFn: downloadVehicleCsvData,
  });

  return downloadVehicleCsvDataMutation;
}
