<script setup>
import BillsTable from "./BillsTable.vue";
import BillsReceiptsTable from "./BillsReceiptsTable.vue";
import ViewBill from "./ViewBill.vue";
import ViewReceipt from "../../receipts/ViewReceipt.vue";
import ViewPdf from "@/components/utils/ViewPdf.vue";
import LabelledField from "@/components/utils/LabelledField.vue";
import PayOrUnselectMultipleBills from "./PayOrUnselectMultipleBills.vue";
import { useViewAllBills } from "./view_bill_business_logic";
import { ref, computed } from "vue";
import { getFileDownloadUrl } from "@/utils/functions";
import { useDownloadFile } from "@/utils/vue_helpers";

const props = defineProps({
  billStatusToFetch: {
    type: String,
    default: null,
  },
  showPayForBill: {
    type: Boolean,
    default: false,
  },
  billIdsSelectedForPayment: {
    type: Array,
    default: () => [],
  },
  showTitle: {
    type: Boolean,
    default: false,
  },
  showPrintReceipt: {
    type: Boolean,
    default: false,
  },
  inBillsReceiptsMode: {
    type: Boolean,
    default: false,
  },
  denseTable: {
    type: Boolean,
    default: false,
  },
  showUnselectBillsBtn: {
    type: Boolean,
    default: false,
  },
  showPayForMultipleBillsBtn: {
    type: Boolean,
    default: false,
  },
});

const {
  viewAllBillsData,
  fetchingBills,
  setBills,
  billsNotEmpty,
  currentPage: currentBillPaginationPage,
} = useViewAllBills({
  billStatusToFetch: props.billStatusToFetch,
});

const paginationComponentLength = computed(() => {
  const numberOfBills = viewAllBillsData.paginationData.total;
  const itemsPerPage = viewAllBillsData.paginationData.perPage;
  const numberOfBillsIsValid = numberOfBills !== 0;
  if (numberOfBillsIsValid) {
    return Math.ceil(numberOfBills / itemsPerPage);
  }
  return null;
});

const bills = computed(() => {
  const billsWithSelectedForPaymentSet = viewAllBillsData.allBills.map(
    (bill) => {
      const billSelectedForPayment =
        props.billIdsSelectedForPayment.find(
          (selectedBillId) => selectedBillId === bill.id
        ) !== undefined;
      if (billSelectedForPayment) {
        bill.selectedForPayment = true;
      } else {
        bill.selectedForPayment = false;
      }
      return bill;
    }
  );
  return billsWithSelectedForPaymentSet;
});

const billReceiptData = computed(() => {
  const billReceiptData = [];
  if (props.inBillsReceiptsMode) {
    viewAllBillsData.allBills.forEach((bill, index) => {
      const receipt = bill.receipt;
      const receiptNotEmpty = !!receipt;
      if (receiptNotEmpty) {
        billReceiptData.push({
          index,
          receiptNumber: receipt.receipt_number,
          receiptAmount: receipt.amount,
          receiptPaymentDate: receipt.payment_date,
          billNumber: bill.bill_number,
          billType: bill.bill_type.name,
        });
      }
    });
  }
  return billReceiptData;
});

function onPaginationClicked(newPage) {
  viewAllBillsData.paginationData.currentPage = newPage;
  setBills();
}

const pages = {
  VIEW_ALL_BILLS: 0,
  VIEW_BILL_OR_RECEIPT: 1,
};
const currentPage = ref(pages.VIEW_ALL_BILLS);

const billUnderView = ref(null);
const billUnderViewNotEmpty = computed(() => {
  return billUnderView.value !== null;
});
const billUnderViewId = computed(() => {
  if (billUnderViewNotEmpty.value) {
    return billUnderView.value.id;
  }
  return null;
});

const receiptUnderView = ref(null);
const receiptUnderViewNotEmpty = computed(() => {
  return receiptUnderView.value !== null;
});
const receiptNumber = computed(() => {
  if (receiptUnderViewNotEmpty.value) {
    return receiptUnderView.value.receipt_number;
  }
  return null;
});

function clearBillAndReceiptUnderView() {
  billUnderView.value = null;
  receiptUnderView.value = null;
}

function onViewBill(bill) {
  console.log(bill);
  billUnderView.value = bill;
  currentPage.value = pages.VIEW_BILL_OR_RECEIPT;
}

const showPdf = ref(false);

const { downloadFile } = useDownloadFile();

function onPrintBill(bill) {
  // billUnderView.value = bill
  // showPdf.value = true
  downloadFile({ billId: bill.id, billNumber: bill.bill_number });
}

function onPrintBillFromItemIndex(itemIndex) {
  const bill = viewAllBillsData.allBills[itemIndex];
  onPrintBill(bill);
  // billUnderView.value = bill
  // showPdf.value = true
}

function onPrintReceipt(itemIndex) {
  clearBillAndReceiptUnderView();
  const bill = viewAllBillsData.allBills[itemIndex];
  const receipt = bill.receipt;
  const receiptNotEmpty = !!receipt;
  if (receiptNotEmpty) {
    downloadFile({ receiptNumber: receipt.receipt_number });
    // receiptUnderView.value = receipt
    // showPdf.value = true
  }
}
function closePdfDialog(isOpen) {
  const dialogShouldBeClosed = isOpen === false;
  if (dialogShouldBeClosed) {
    showPdf.value = false;
    billUnderView.value = null;
    receiptUnderView.value = null;
  }
}

function goToBillsTable() {
  currentPage.value = pages.VIEW_ALL_BILLS;
}

function onViewReceipt(itemIndex) {
  const bill = viewAllBillsData.allBills[itemIndex] ?? null;
  const billNotEmpty = !!bill;
  if (billNotEmpty) {
    const receipt = bill.receipt;
    receiptUnderView.value = receipt;
    currentPage.value = pages.VIEW_BILL_OR_RECEIPT;
  }
}
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-tabs-items v-model="currentPage" touchless>
          <v-tab-item>
            <v-container fluid class="pa-0">
              <v-row justify-md="center">
                <v-col cols="12" md="6">
                  <LabelledField label="Search">
                    <v-text-field
                      class="background text-field"
                      outlined
                      dense
                      v-model="viewAllBillsData.searchModel"
                      clearable
                    />
                  </LabelledField>
                </v-col>
              </v-row>

              <PayOrUnselectMultipleBills
                :showUnselectBillsBtn="showUnselectBillsBtn"
                :showPayForMultipleBillsBtn="showPayForMultipleBillsBtn"
                @unselectBillsClicked="$emit('unselectBillsClicked')"
                @payForMultipleBillsClicked="
                  $emit('payForMultipleBillsClicked')
                "
              />

              <v-row>
                <v-col cols="12">
                  <BillsReceiptsTable
                    v-if="inBillsReceiptsMode"
                    :billReceiptData="billReceiptData"
                    :loading="fetchingBills"
                    @viewReceipt="onViewReceipt"
                    @printReceipt="onPrintReceipt"
                    @printBill="onPrintBillFromItemIndex"
                  />

                  <BillsTable
                    v-else
                    :bills="bills"
                    :loading="fetchingBills"
                    :showPayForBill="props.showPayForBill"
                    :billIdsSelectedForPayment="props.billIdsSelectedForPayment"
                    :showPrintReceipt="props.showPrintReceipt"
                    :dense="denseTable"
                    @viewBill="onViewBill"
                    @printBill="onPrintBill"
                    @payForBill="$emit('payForBill', $event)"
                  >
                    <template
                      v-if="$scopedSlots.multiSelect"
                      #multiSelect="{ bill }"
                    >
                      <slot name="multiSelect" :bill="bill" />
                    </template>

                    <template
                      v-if="$scopedSlots.paymentButton"
                      #paymentButton="{ bill }"
                    >
                      <slot name="paymentButton" :bill="bill" />
                    </template>
                    <template
                      v-if="$scopedSlots.selectBill"
                      #selectBill="{ bill }"
                    >
                      <slot name="selectBill" :bill="bill" />
                    </template>
                  </BillsTable>
                </v-col>
              </v-row>

              <PayOrUnselectMultipleBills
                :showUnselectBillsBtn="showUnselectBillsBtn"
                :showPayForMultipleBillsBtn="showPayForMultipleBillsBtn"
                @unselectBillsClicked="$emit('unselectBillsClicked')"
                @payForMultipleBillsClicked="
                  $emit('payForMultipleBillsClicked')
                "
              />

              <v-row justify="end">
                <v-col cols="auto">
                  <v-pagination
                    v-if="paginationComponentLength !== null && billsNotEmpty"
                    :length="paginationComponentLength"
                    total-visible="10"
                    :value="currentBillPaginationPage"
                    @input="onPaginationClicked"
                    circle
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <v-tab-item>
            <ViewReceipt
              v-if="inBillsReceiptsMode"
              :receipt="receiptUnderView"
              showGoBack
              @goBackClicked="currentPage = pages.VIEW_ALL_BILLS"
            />
            <ViewBill
              v-else
              :bill="billUnderView"
              showGoBack
              @goBackClicked="goToBillsTable()"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <ViewPdf
      v-if="billUnderViewNotEmpty || receiptUnderViewNotEmpty"
      :billId="billUnderViewId"
      :receiptNumber="receiptNumber"
      :open="showPdf"
      @update:open="closePdfDialog"
    />
  </v-container>
</template>
