var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Table,{attrs:{"headers":[
    {
      text: 'Payment Method',
      value: 'paymentMethod',
      fn: (paymentMethod) => paymentMethod.pay_mode.pay_mode,
    },
    {
      text: 'Reference Number',
      value: 'ref_number'
    },
    {
      text: 'Amount',
      value: 'amount',
      fn: (paymentMethod) => _setup.getFormattedMoney(paymentMethod.amount),
    },
    {
      text: 'Payment Date',
      value: 'paymentDate',
      fn: (paymentMethod) => _setup.getFormattedBillDate(paymentMethod.payment_date),
    },
  ],"items":_vm.paymentMethods}})
}
var staticRenderFns = []

export { render, staticRenderFns }