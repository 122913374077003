<script setup>
import LoadingArea from "@/components/utils/LoadingArea.vue";
import ActionButton from "@/components/utils/ActionButton.vue";
import ActionsContainer from "@/components/utils/ActionsContainer.vue";
import Table from "@/components/utils/Table.vue";
import { actions } from "@/utils/constants";
import { getFormattedBillDate, getFormattedMoney } from "@/utils/functions";
import { computed } from "vue";

const props = defineProps({
  billReceiptData: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const billReceiptDataNotEmpty = computed(() => {
  return (
    props.billReceiptData !== null &&
    props.billReceiptData !== undefined &&
    props.billReceiptData.length > 0
  );
});
</script>

<template>
  <LoadingArea :loading="loading">
    <Table
      :headers="[
        { text: 'Receipt Number', value: 'receiptNumber' },
        { text: 'Amount', value: 'amount' },
        { text: 'Receipt Date', value: 'receiptPaymentDate' },
        { text: 'Bill Number', value: 'billNumber' },
        { text: 'Bill Type', value: 'billType' },
        { text: 'View', value: 'view', action: true },
        { text: 'Download Receipt', value: 'downloadReceipt', action: true },
        { text: 'Download Bill', value: 'downloadBill', action: true },
      ]"
      :items="billReceiptData"
      :loading="loading"
      emptyText="No Receipts Found"
      headClasses="primary white--text"
      :headStyles="{ fontSize: '10px' }"
    >
      <template #itemTitle="{ item: billReceiptItem }">
        {{ billReceiptItem.receiptNumber }}
      </template>
      <template #item.amount="{ item: billReceiptItem }">
        {{ getFormattedMoney(billReceiptItem.receiptAmount) }}
      </template>
      <template #item.receiptPaymentDate="{ item: billReceiptItem }">
        {{ getFormattedBillDate(billReceiptItem.receiptPaymentDate, true) }}
      </template>
      <template #item.view="{ item: billReceiptItem }">
        <ActionButton
          :action="actions.view"
          inTable
          @click="$emit('viewReceipt', billReceiptItem.index)"
        />
      </template>
      <template #item.downloadReceipt="{ item: billReceiptItem }">
        <ActionButton
          :action="actions.downloadReceipt"
          inTable
          @click="$emit('printReceipt', billReceiptItem.index)"
        />
      </template>
      <template #item.downloadBill="{ item: billReceiptItem }">
        <ActionButton
          :action="actions.downloadBill"
          inTable
          @click="$emit('printBill', billReceiptItem.index)"
        />
      </template>
    </Table>

    <v-simple-table v-if="false">
      <template #default>
        <thead>
          <tr class="primary white--text">
            <th class="text-left white--text">Receipt Number</th>
            <th class="text-left white--text">Amount</th>
            <th class="text-left white--text">Receipt Date</th>
            <th class="text-left white--text">Bill Number</th>
            <th class="text-left white--text">Bill Type</th>
            <th class="text-right white--text">Actions</th>
          </tr>
        </thead>

        <tbody>
          <template v-if="billReceiptDataNotEmpty">
            <tr v-for="billReceiptItem in billReceiptData">
              <td>
                {{ billReceiptItem.receiptNumber }}
              </td>
              <td>
                {{ getFormattedMoney(billReceiptItem.receiptAmount) }}
              </td>
              <td>
                {{
                  getFormattedBillDate(billReceiptItem.receiptPaymentDate, true)
                }}
              </td>
              <td>
                {{ billReceiptItem.billNumber }}
              </td>
              <td>
                {{ billReceiptItem.billType }}
              </td>
              <td align="right">
                <div class="d-flex flex-wrap justify-end align-center">
                  <ActionsContainer>
                    <ActionButton
                      :action="actions.view"
                      inTable
                      @click="$emit('viewReceipt', billReceiptItem.index)"
                    />

                    <ActionButton
                      :action="actions.downloadReceipt"
                      inTable
                      @click="$emit('printReceipt', billReceiptItem.index)"
                    />

                    <ActionButton
                      :action="actions.downloadBill"
                      inTable
                      @click="$emit('printBill', billReceiptItem.index)"
                    />
                  </ActionsContainer>
                </div>
              </td>
            </tr>
          </template>
          <tr v-else-if="loading">
            <td colspan="6">
              <div class="text-center">Loading</div>
            </td>
          </tr>
          <tr v-else>
            <td colspan="6">
              <div class="text-center">No Receipts</div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </LoadingArea>
</template>
