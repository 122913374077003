<script setup>
import { getFormattedMoney, getFormattedBillDate } from "@/utils/functions";
import {
  getVueInstance,
  useDownloadFile,
  useVehiclesFromReceipt,
} from "@/utils/vue_helpers";
import { computed, ref } from "vue";
import BackButton from "../utils/BackButton.vue";
import ViewPdf from "../utils/ViewPdf.vue";
import ReceiptPaymentMethods from "./ReceiptPaymentMethods.vue";
import Table from "../utils/Table.vue";
import { useBulkDownloadVehicleStickers } from "../vehicles/view_vehicles_logic";
import { setSnackbar } from "@/components/utils/snackbar/logic";

const props = defineProps({
  receipt: {
    type: Object,
    default: () => null,
  },

  showGoBack: {
    type: Boolean,
    default: true,
  },
});

const summarizedPaymentMethods = computed(() => {
  return props.receipt.summarized_payment_methods;
});

const viewReceiptPdf = ref(false);

function getBillData(receipt) {
  const billData = [];
  for (let invoiceReceipt of receipt.invoice_receipts) {
    const bill = invoiceReceipt.invoice.bills_invoice.bill;
    billData.push({
      billNumber: bill.bill_number,
      formattedAmount: getFormattedMoney(invoiceReceipt.invoice.invoice_amount),
      status: bill.work_flow_status.status_name,
    });
  }

  return billData;
}

const { downloadFile } = useDownloadFile();

function onDownloadReceiptClicked() {
  downloadFile({ receiptNumber: props.receipt.receipt_number });
}

const { vuetify } = getVueInstance();

const receiptDetailRows = computed(() => {
  return {
    row1: {
      "Receipt Number": props.receipt.receipt_number,
      ...(vuetify.breakpoint.mdAndUp
        ? { Amount: getFormattedMoney(props.receipt.amount) }
        : {}),
    },
    row2: {
      Date: getFormattedBillDate(props.receipt.payment_date),
      ...(vuetify.breakpoint.mdAndUp
        ? { Status: props.receipt.work_flow_status.status_name }
        : {}),
    },
    ...(vuetify.breakpoint.smAndDown
      ? {
          row3: {
            Amount: getFormattedMoney(props.receipt.amount),
          },
          row4: {
            Status: props.receipt.work_flow_status.status_name,
          },
        }
      : {}),
  };
});

const { vehicles: vehiclesFromReceipt, receiptHasVehicles } =
  useVehiclesFromReceipt(props.receipt);
function onDownloadVehicleStickersClicked() {
  if (receiptHasVehicles.value) {
    downloadFile({ vehicles: vehiclesFromReceipt.value });
  }
}
</script>

<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col v-if="showGoBack" cols="12" md="1">
        <BackButton @click="$emit('goBackClicked')">
          Back to Receipts
        </BackButton>
      </v-col>
      <v-col cols="12" md="10" class="text-h6 primary--text text-center">
        Receipt Summary
      </v-col>
      <v-spacer v-if="showGoBack" />
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-simple-table class="table-container no-bottom-border">
          <template #default>
            <tbody>
              <tr v-for="receiptDetailRow in receiptDetailRows">
                <template
                  v-for="[title, value] in Object.entries(receiptDetailRow)"
                >
                  <td
                    :class="{
                      'font-weight-bold px-0 px-md-4': true,
                    }"
                  >
                    {{ title }}
                  </td>
                  <td
                    :class="{
                      'px-0 pl-3 px-md-4': true,
                      'font-weight-bold success--text':
                        title === 'Amount' || title === 'Status',
                    }"
                  >
                    {{ value }}
                  </td>
                </template>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row justify="center" justify-md="end">
      <v-col cols="auto">
        <v-btn
          class="text-none"
          color="success"
          @click="onDownloadReceiptClicked"
        >
          <span>Download Receipt</span
          ><v-icon right>mdi-download-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="receiptHasVehicles" justify="center" justify-md="end">
      <v-col cols="auto">
        <v-btn
          class="text-none"
          color="secondary"
          @click="onDownloadVehicleStickersClicked"
        >
          <span>Download Stickers</span><v-icon right>mdi-printer</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="my-2 mx-1">
      <v-col cols="12">
        <v-divider />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-tabs>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab class="text-none">Bills</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <Table
          :headers="[
            {
              text: 'Bill Number',
              value: 'billNumber',
            },
            {
              text: 'Amount',
              value: 'formattedAmount',
            },
            {
              text: 'Status',
              value: 'status',
            },
          ]"
          :items="getBillData(receipt)"
        >
        </Table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="auto">
        <v-tabs>
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab class="text-none">Payment Methods</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <ReceiptPaymentMethods :paymentMethods="summarizedPaymentMethods" />
      </v-col>
    </v-row>

    <ViewPdf
      v-if="viewReceiptPdf"
      :receiptNumber="receipt.receipt_number"
      :open.sync="viewReceiptPdf"
    />
  </v-container>
</template>
