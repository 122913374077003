var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[(_vm.showGoBack)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c(_setup.BackButton,{on:{"click":function($event){return _vm.$emit('goBackClicked')}}},[_vm._v(" Back to Receipts ")])],1):_vm._e(),_c('v-col',{staticClass:"text-h6 primary--text text-center",attrs:{"cols":"12","md":"10"}},[_vm._v(" Receipt Summary ")]),(_vm.showGoBack)?_c('v-spacer'):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{staticClass:"table-container no-bottom-border",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_setup.receiptDetailRows),function(receiptDetailRow){return _c('tr',[_vm._l((Object.entries(receiptDetailRow)),function([title, value]){return [_c('td',{class:{
                    'font-weight-bold px-0 px-md-4': true,
                  }},[_vm._v(" "+_vm._s(title)+" ")]),_c('td',{class:{
                    'px-0 pl-3 px-md-4': true,
                    'font-weight-bold success--text':
                      title === 'Amount' || title === 'Status',
                  }},[_vm._v(" "+_vm._s(value)+" ")])]})],2)}),0)]},proxy:true}])})],1)],1),_c('v-row',{attrs:{"justify":"center","justify-md":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none",attrs:{"color":"success"},on:{"click":_setup.onDownloadReceiptClicked}},[_c('span',[_vm._v("Download Receipt")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-download-circle")])],1)],1)],1),(_setup.receiptHasVehicles)?_c('v-row',{attrs:{"justify":"center","justify-md":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"text-none",attrs:{"color":"secondary"},on:{"click":_setup.onDownloadVehicleStickersClicked}},[_c('span',[_vm._v("Download Stickers")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-printer")])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"my-2 mx-1"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_c('v-tab',{staticClass:"text-none"},[_vm._v("Bills")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c(_setup.Table,{attrs:{"headers":[
          {
            text: 'Bill Number',
            value: 'billNumber',
          },
          {
            text: 'Amount',
            value: 'formattedAmount',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],"items":_setup.getBillData(_vm.receipt)}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_c('v-tab',{staticClass:"text-none"},[_vm._v("Payment Methods")])],1)],1)],1),_c('v-row',[_c('v-col',[_c(_setup.ReceiptPaymentMethods,{attrs:{"paymentMethods":_setup.summarizedPaymentMethods}})],1)],1),(_setup.viewReceiptPdf)?_c(_setup.ViewPdf,{attrs:{"receiptNumber":_vm.receipt.receipt_number,"open":_setup.viewReceiptPdf},on:{"update:open":function($event){_setup.viewReceiptPdf=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }