import { userProfile, userProfileNotEmpty } from "@/utils/vue_helpers";
import apiCall from "@/utils/api";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { requestStatus } from "@/utils/constants";
import { stringNotEmpty, debounce } from '@/utils/functions'

const initalPaginationData = {
    currentPage: 1,
    perPage: 10,
    total: 0,
    visible: 10
}

export const billStatuses = {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    ALL: 'ALL'
}

export function useViewAllBills({ billStatusToFetch = null }) {
    const viewAllBillsData = reactive({
        allBills: [],
        paginationData: {
            ...initalPaginationData
        },
        searchModel: ''
    })

    const getBillsRequestStatus = ref(requestStatus.NOT_SENDING)
    const fetchingBills = computed(() => {
        return getBillsRequestStatus.value === requestStatus.SENDING
    })
    const billsNotEmpty = computed(() => {
        const bills = viewAllBillsData.allBills
        return bills !== null && bills.length > 0
    })

    const currentPage = computed(() => {
        return viewAllBillsData.paginationData.currentPage
    })

    const debouncedFetchBill = debounce(() => {
        setToFirstPage()
        setBills()
    }, 1000)
    const getSearchModel = computed(() => {
        return viewAllBillsData.searchModel
    })
    watch(getSearchModel, () => {
        debouncedFetchBill()
    })


    function setToFirstPage() {
        viewAllBillsData.paginationData.currentPage = 1
    }

    function setAllBillsPaginationData(serverResponse) {
        viewAllBillsData.paginationData.currentPage = serverResponse.current_page
        viewAllBillsData.paginationData.total = serverResponse.total
        viewAllBillsData.paginationData.perPage = serverResponse.per_page
    }

    async function setBills() {
        if (userProfileNotEmpty.value) {
            const customerNumber = userProfile.value.customer_number
            const currentPage = viewAllBillsData.paginationData.currentPage
            let url = `/api/client/bill/for-client/${customerNumber}?page=${currentPage}`
            const searchFieldValue = viewAllBillsData.searchModel
            const searchFieldNotEmpty = stringNotEmpty(searchFieldValue)
            if (searchFieldNotEmpty) {
                url = url + `&type=search&search=${searchFieldValue}`
            }
            const billStatusToFetchNotEmpty = billStatusToFetch !== null && billStatusToFetch !== undefined
            if (billStatusToFetchNotEmpty) {
                url = url + `&bill_status=${billStatusToFetch}`
            }
            try {
                getBillsRequestStatus.value = requestStatus.SENDING
                const response = await apiCall({
                    url, method: 'GET'
                })
                setAllBillsPaginationData(response)
                viewAllBillsData.allBills = response.data
                getBillsRequestStatus.value = requestStatus.COMPLETE
            } catch (error) {
                getBillsRequestStatus.value = requestStatus.ERRROR
            }
        }
    }



    function resetViewAllBillsData() {
        viewAllBillsData.allBills = []
        viewAllBillsData.paginationData = { ...initalPaginationData }
    }

    onMounted(async () => {
        resetViewAllBillsData()
        await setBills()
    })

    watch(userProfileNotEmpty, () => {
        if (userProfileNotEmpty.value) {
            setBills()
        }
    })

    return { viewAllBillsData, fetchingBills, billsNotEmpty, setBills, currentPage }
}
