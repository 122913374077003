<script setup>
import ActionButton from "@/components/utils/ActionButton.vue";
import ActionsContainer from "@/components/utils/ActionsContainer.vue";
import Table from "@/components/utils/Table.vue";
import { actions } from "@/utils/constants";
import {
  getFormattedBillDate,
  getBillAmount,
  getFormattedMoney,
} from "@/utils/functions";
import { computed, getCurrentInstance } from "vue";

const props = defineProps({
  bills: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showBillStatus: {
    type: Boolean,
    default: false,
  },
  showPayForBill: {
    type: Boolean,
    default: false,
  },
  showPrintReceipt: {
    type: Boolean,
    default: false,
  },
  dense: {
    type: Boolean,
    default: false,
  },
});

const billsNotEmpty = computed(() => {
  return (
    props.bills !== null && props.bills !== undefined && props.bills.length > 0
  );
});

const tableHeaders = computed(() => {
  const currentInstance = getCurrentInstance();
  const headers = [];

  const showMultiSelect = currentInstance.proxy.$scopedSlots.multiSelect;
  if (showMultiSelect) {
    headers.push({ text: "Select", value: "multi_select" });
  }

  headers.push(
    { text: "Bill Number", value: "bill_number" },
    { text: "Bill Type", value: "bill_type" }
  );
  if (props.showBillStatus) {
    headers.push({ text: "Bill Status", value: "bill_status" });
  }
  headers.push(
    { text: "Bill Amount", value: "bill_amount" },
    { text: "Bill Date", value: "bill_date" },
    { text: "View", value: "view", action: true },
    { text: "Download", value: "download", action: true }
  );

  const showPayHeader = currentInstance.proxy.$scopedSlots.paymentButton;
  if (showPayHeader) {
    headers.push({ text: "Pay", value: "pay", action: true });
  }

  const showSelectHeader = currentInstance.proxy.$scopedSlots.selectBill;
  if (showSelectHeader) {
    headers.push({ text: "Select", value: "select", action: true });
  }

  return headers;
});
</script>

<template>
  <div :style="{ position: 'relative' }">
    <v-container fluid class="pa-0">
      <v-row v-if="loading">
        <v-col cols="12">
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Table
            :headers="tableHeaders"
            :items="props.bills"
            :loading="loading"
            emptyText="No Bills Found"
            headClasses="primary white--text"
          >
            <template #itemTitle="{ item: bill }">
              {{ bill.bill_number }}
            </template>

            <template #item.multi_select="{ item: bill }">
              <slot v-if="$scopedSlots" name="multiSelect" :bill="bill"> </slot>
            </template>

            <template #item.bill_type="{ item: bill }">
            {{ (bill?.bill_type?.name)=== "House/Stall bill"?"My Houses/ Stalls": bill?.bill_type?.name}}
            </template>

            <template #item.bill_amount="{ item: bill }">
              {{ getFormattedMoney(getBillAmount(bill)) }}
            </template>

            <template #item.bill_date="{ item: bill }">
              {{ getFormattedBillDate(bill.created_at) }}
            </template>

            <template #item.view="{ item: bill }">
              <ActionButton
                :action="actions.view"
                inTable
                @click="$emit('viewBill', bill)"
              />
            </template>

            <template #item.download="{ item: bill }">
              <ActionButton
                :action="actions.downloadBill"
                inTable
                @click="$emit('printBill', bill)"
              />
            </template>

            <template #item.pay="{ item: bill }">
              <slot
                v-if="$scopedSlots.paymentButton"
                name="paymentButton"
                :bill="bill"
              >
              </slot>
            </template>

            <template #item.select="{ item: bill }">
              <slot
                v-if="$scopedSlots.selectBill"
                name="selectBill"
                :bill="bill"
              >
              </slot>
            </template>

            <template v-if="showBillStatus" #items.bill_status="{ item: bill }">
              {{ bill.work_flow_status.status_name }}
            </template>
          </Table>

          <v-simple-table v-if="false" :dense="dense">
            <template #default>
              <thead>
                <tr class="primary white--text">
                  <th class="text-left white--text">Bill Number</th>
                  <th class="text-left white--text">Bill Type</th>
                  <th v-if="showBillStatus" class="text-left white--text">
                    Bill Status
                  </th>
                  <th class="text-left white--text">Bill Amount</th>
                  <th class="text-left white--text">Date</th>
                  <th class="text-right white--text">Actions</th>
                </tr>
              </thead>

              <tbody>
                <template v-if="billsNotEmpty">
                  <tr v-for="bill in props.bills">
                    <td>
                      <div>
                        {{ bill.bill_number }}
                      </div>
                    </td>
                    <td>
                      <div>
                       {{ bill.bill_type.name }}
                      </div>
                    </td>
                    <td v-if="showBillStatus">
                      <div>
                        {{ bill.work_flow_status.status_name }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ getFormattedMoney(getBillAmount(bill)) }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ getFormattedBillDate(bill.created_at) }}
                      </div>
                    </td>
                    <td align="right">
                      <ActionsContainer>
                        <ActionButton
                          :action="actions.view"
                          inTable
                          @click="$emit('viewBill', bill)"
                        />

                        <ActionButton
                          :action="actions.downloadBill"
                          inTable
                          @click="$emit('printBill', bill)"
                        />

                        <slot
                          v-if="$scopedSlots.paymentButton"
                          name="paymentButton"
                          :bill="bill"
                        >
                        </slot>

                        <slot
                          v-if="$scopedSlots.selectBill"
                          name="selectBill"
                          :bill="bill"
                        >
                        </slot>
                      </ActionsContainer>
                    </td>
                  </tr>
                </template>
                <tr v-else-if="loading">
                  <td colspan="6">
                    <div class="text-center">Loading</div>
                  </td>
                </tr>
                <tr v-else>
                  <td colspan="6">
                    <div class="text-center">No bills</div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>

    <v-overlay v-if="loading" absolute opacity="0.5" color="white" />
  </div>
</template>
